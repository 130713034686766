<template>
  <div class="block" :class="{ [runtimeConfig.app.backgroundColorAlt]: bgAlt, [$style.bgSkewed]: bgSkewed }">
    <div :class="$style.container">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();

defineProps<{
  bgAlt?: boolean;
  bgSkewed?: boolean;
}>();
</script>

<style scoped>
.block {
  @apply w-full;
}
</style>

<style module>
@import url("~/assets/css/modules.css");

.bgSkewed {
  @apply py-12 my-12 border-y border-zinc-700;
  transform: skew(0, -3deg);

  > div {
    transform: skew(0, 3deg);
  }
}
</style>
